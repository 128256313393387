<template>
  <div v-if="staffContacts.length">
    <h2 class="text-center">{{ $t('Meet the team') }}</h2>

    <div class="mt-1 pl-4 pr-4 text-center">
      <b-row class="" align-h="center">
        <b-col class="mb-1 d-flex align-items-center flex-column container-card" v-for="contact in contactData" :key="contact.id">
          <div class="staff-card d-flex align-items-center flex-column p-1 mb-1 col-card">
            <!-- <b-img
              v-if="!!contact.profilepicture"
              :src="imgUrl + contact.profilepicture"
              class="m-1 circle-img"
              height="160"
              @error="imgDefault"
            /> -->
            <div class="upload__img-wrap justify-content-center">
              <div class="upload__img-box">
                <span
                  :href="sourceFile(contact)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div :style="`background-image: url('${sourceFile(contact)}');`" class='img-bg' v-if="!!sourceFile(contact)"></div>
                  <div v-else class="img-bg default-image"></div>
                </span>

              </div>
            </div>

            <!-- <img class="m-1 circle-img" :src="imgDefault" alt="user" v-else/> -->

            <div class="">
              <span v-if="!!contact.fullname" class="font-weight-bolder">{{contact.fullname}}</span><br>
              <label v-if="!!contact.jobTitleEng && language == 'en'">{{contact.jobTitleEng}}</label>
              <label v-if="!!contact.jobTitle && language == 'es'">{{contact.jobTitle}}</label>
            </div>

            <div class="mb-2 w-100">
              <a v-if="!!contact.email" @click="openLink('contactedByEmail', 'emailInfo', contact.fullname, `mailto:${contact.email}`)" class="font-contact-data text-warning"
              >{{contact.email}}</a> <br v-if="!!contact.email">
              <a v-if="!!contact.phone" @click="openLink('contactedByPhone', 'phoneInfo', contact.fullname, `tel:${contact.phone}`)" class="font-contact-data text-warning"
              >{{contact.phone}}</a>
            </div>

            <b-button
              v-if="!!contact.url"
              target="_blank"
              rel="noopener noreferrer"
              variant="primary"
              class="mt-auto"
              @click="requestAMeeting('scheduledMeeting', 'scheduleMeetingButton', contact.fullname, contact.url)"
            >{{ $t('Schedule a meeting') }}
            </b-button>
          </div>

        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { utils } from "@/modules/owners/mixins/utils"

export default {
  mixins: [ utils ],
  data(){
    return {
      imgDefault: require('@/assets/images/default-profile.png'),
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      language: this.$i18n.locale
    }
  },
  computed: {
    ...mapState('owners', ['staffContacts']),
    contactData(){
      return this.staffContacts.sort((a, b) => a.priority > b.priority ? 1 : -1)
    }
  },
  methods: {
    async requestAMeeting(action, component, staff, url){
      window.open(url, '_blank');
			await this.pushData('onClick', action, component, this.$route.name, `- scheduled with ${staff}` ) // push a back del registro
    },
    async openLink(action, component, staff, url){
      window.open(url);
			await this.pushData('onClick', action, component, this.$route.name, `- ${staff}` ) // push a back del registro
    },
    sourceFile(contact){
      if (!!contact.profilepicture) return this.imgUrl + contact.profilepicture
      else return null
    }
  }

}
</script>

<style>
.font-contact-data {
  font-size: smaller;
}
.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.upload__img-box {
  width: 150px;
  height: 150px;
  padding: 0 5px;
  margin-bottom: 1rem;
}
.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 100%;
  margin-block-end: 1rem;
}
.default-image{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
  background-image: url('~@/assets/images/default-profile.png');
}

.circle-img {
	border: 1px solid #03BFCB;
	padding: 3px;
  width: 160px;
  height: 160px;
  border-radius: 100%;

  margin-block-end: 1rem;
}

@media (max-width: 1280px) {
  .circle-img {
    border: 1px solid #03BFCB;
    padding: 3px;
    width: 120px;
    height: 120px;
    border-radius: 100%;

    margin-block-end: 1rem;
  }
  .circle-img .btn {
    margin: 0.5px;
  }

  .upload__img-box {
    width: 120px;
    height: 120px;
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 768px) {
  .circle-img {
    border: 1px solid #03BFCB;
    padding: 3px;
    width: 120px;
    height: 120px;
    border-radius: 100%;

    margin-block-end: 1rem;
  }

  .circle-img .btn {
    margin: 0.5px;
  }

  .upload__img-box {
    width: 120px;
    height: 120px;
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

.staff-card{
  border: solid 1px #F1F1F1 !important;
  box-shadow: 0 4px 18px 0 rgba(34, 41, 47, 0.1);
  height: 100%;
  width: 75%;
}

.container-card{
  min-width: 20rem !important;
  max-width: 20rem !important;
}

.col-card{
  min-width: 17rem !important;
  max-width: 17rem !important;
  /* height: 280px;
  width: 270px; */
}
</style>
<!-- 280 x 270 -->
<!-- 224.8 -->