<template>
  <b-card class="m-1">
    <OurTeamList class="mb-2" v-if="!isloadingStaffData" />

    <div class="text-center p-4" v-else>
      <b-spinner /> <br> {{ $t('loading') }}...
    </div>

    <ContactForm />
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import OurTeamList from "@/modules/owners/components/staff/OurTeamList"
import ContactForm from "@/modules/owners/components/staff/ContactForm"

export default {
  components: {
    OurTeamList,
    ContactForm
  },
  async created(){
    await this.init()
  },
  data(){
    return {
      isloadingStaffData: false,
    }
  },
  methods: {
    ...mapActions('owners', ['fetchStaffContacts']),
    ...mapMutations('owners', ['setStaffContacts']),
    async init(){
      this.isloadingStaffData = true
      const response = await this.fetchStaffContacts()
      this.setStaffContacts(response)
      this.isloadingStaffData = false
    }
  }
}
</script>

<style>

</style>