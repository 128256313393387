<template>
  <div class="custom-padding-team text-center">
    <h2>{{ $t('Get in touch') }}</h2>

    <b-row class="mb-1">
      <b-col sm="6" lg="6" class="d-lg-flex text-center pt-1 ">
        <div class="w-100 phoneContent">
          <h6 class="font-weight-bolder">Call Center</h6>
          <div class="ulClass">
            <a href="tel:+529842420151" target="_blank">+52 984 242 0151</a>
          </div>
        </div>
      </b-col>
      <b-col sm="6" lg="6" class="d-lg-flex align-items-center pt-1">
        <div class="w-100 phoneContent">
          <h6 class="font-weight-bolder">{{$t('Toll free')}}</h6>
          <div class="ulClass pl-1">
            {{$t('From')}} Mexico: <a href="tel:8006811588" target="_blank"> 800 681 1588</a> <br>
            USA &amp; Canada:  <a href="tel:18668653664" target="_blank">1 (866) 865-3664</a>
          </div>
        </div>
      </b-col>
    </b-row>

    <div id="hubspotFormStaffContact" v-once></div>

  </div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script")
    // script.src="https://js.hsforms.net/forms/v2.js"
    script.src="https://js.hsforms.net/forms/embed/v2.js"
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        const formId = this.$i18n.locale == 'es' ? process.env.VUE_APP_HUBSPOT_FORM_STAFF_CONTACT_SPA_ID : process.env.VUE_APP_HUBSPOT_FORM_STAFF_CONTACT_ENG_ID
        window.hbspt.forms.create({
          portalId: process.env.VUE_APP_HUBSPOT_PORTAL_ID, // "your-portal-id"
          formId, // "your-form-id"
          region: process.env.VUE_APP_HUBSPOT_FORM_REGION,
          target: "#hubspotFormStaffContact"
        })
      } else throw Error("Unable to load HubSpot JS")
    })
  }
}
</script>

<style>
.custom-padding-team{
  padding: 0 15rem;
}

@media (max-width: 1280px) {
  .custom-padding-team{
    padding: 0 10rem;
  }
}
@media (max-width: 768px) {
  .custom-padding-team{
    padding: 0 5rem;
  }
}

</style>

